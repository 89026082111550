import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import { prepareGrid } from 'utils/portfolio-grid'
import PortfolioPageTpl from 'templates/portfolio/page'

function PortfolioExteriorPage({
	data: {
		prismicPortfolioPage: {
			data: { heading, paragraph, grid },
		},
	},
}) {
	const content = {
		category: 'Exterior',
		grid: prepareGrid(grid),
		top: { heading, paragraph },
	}

	return <PortfolioPageTpl {...content} />
}

export default withPrismicPreview(PortfolioExteriorPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])

export const query = graphql`
	query PortfolioExteriorQuery {
		prismicPortfolioPage(uid: { eq: "exterior" }) {
			_previewable
			data {
				...PortfolioPageFragment
			}
		}
	}
`
